import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
	RecipientType,
} from '../../../../helpers/custom/common';
import TypographyText from '../../../atoms/ui/typography-text';
import TextChip from '../../../atoms/ui/text-chip/TextChip';
import TextChipsGroup from '../../../molecules/ui/text-chips-group/TextChipsGroup';
import ButtonLink from '../../../atoms/buttons/button-link';
import SectionTitle from '../../../atoms/ui/section-title';
import ManageRecipientsScreen from '../manage-recipients-screen';
import {
	changeJobNotificationsSettings,
	getJobSubscribers,
} from '../../../../store/slices/user.slice';
import Loader from '../../../molecules/loader';

import styles from './JobSingleNotificationSettings.module.scss';
import JobSingleNotificationsSettingInterface from './job-single-notification-settings.interface';

const JobSingleNotificationSettings: FC<JobSingleNotificationsSettingInterface> = ({jobId,settingsName,  settingsLabel,  settingsDescription}) => {
	const dispatch = useDispatch();


	const [manageRecipientsOpen, setManageRecipientsOpen] = useState(false);

	const [allRecipients, setAllRecipients] = useState<RecipientType[]>([]);
	const activeRecipients = allRecipients.filter((recipient) => recipient.active);
	const [recipientsLoading, setRecipientsLoading] = useState(false);
	const [removingInProgress, setRemovingInProgress] = useState(false);


	const onRecipientRemove = useCallback(
		async (id: number) => {
			if (jobId && id) {
				setRemovingInProgress(true);

				const result = await dispatch(
					changeJobNotificationsSettings(jobId, [
						{
							settingsName: settingsName,
							settingsValue: false,
							userId: id,
						},
					]),
				);

				if (!result) {
					const recipients = await dispatch(
						getJobSubscribers(jobId, settingsName),
					);

					//@ts-ignore
					if (recipients) setAllRecipients(recipients);
				}

				setRemovingInProgress(false);
			}
		},
		[dispatch, jobId, settingsName],
	);

	const renderRecipients = useMemo(() => {
		if (activeRecipients && activeRecipients.length) {
			return (
				<div className={styles.recipients}>
					<TextChipsGroup>
						{activeRecipients.map((recipient) => {
							return (
								<TextChip
									key={recipient.id}
									item={{
										id: recipient.id,
										text: `${recipient.firstName} ${recipient.lastName}`,
									}}
									onRemoveHandler={() => onRecipientRemove(recipient.id)}
								/>
							);
						})}
					</TextChipsGroup>
				</div>
			);
		} else {
			return (
				<TypographyText className={styles.empty}>
					No recipients have been subscribed to this notification.
				</TypographyText>
			);
		}
	}, [activeRecipients, onRecipientRemove]);

	const updateRecipients = useCallback(async () => {
		if (jobId) {
			(async () => {
				setRecipientsLoading(true);

				const recipients = await dispatch(
					getJobSubscribers(jobId, settingsName),
				);

				//@ts-ignore
				if (recipients) setAllRecipients(recipients);
				setRecipientsLoading(false);
			})();
		}
	}, [dispatch, jobId, settingsName]);

	const afterRecipientsChange = useCallback(() => updateRecipients(), [updateRecipients]);

	const renderSingleNotificationSection = useMemo(() => {
		return (
			<div className={styles.notification}>

				<SectionTitle className={styles['section-title']}>
				{ settingsLabel }
					{removingInProgress ? (
						<Loader
							className={styles['title-loader']}
							maxWidth={15}
							maxHeight={15}
							thin
						/>
					) : null}
					<ButtonLink
						className={styles['btn-link']}
						onClick={() => setManageRecipientsOpen(true)}
					>
						{recipientsLoading
							? ''
							: activeRecipients && activeRecipients.length
							? 'Manage recipients'
							: 'Add recipients'}
					</ButtonLink>
				</SectionTitle>

				<TypographyText className={styles.description}>
				{ settingsDescription}
				</TypographyText>

				{recipientsLoading ? (
					<Loader className={styles.loader} maxHeight={20} maxWidth={20} thin />
				) : (
					renderRecipients
				)}
			</div>
		);
	}, [activeRecipients, recipientsLoading, removingInProgress, renderRecipients, settingsLabel,settingsDescription]);


	useEffect(() => {
		updateRecipients();
	}, [jobId, updateRecipients]);

	return (
		<>
			<div className={styles['job-single-notification-settings']}>
				{renderSingleNotificationSection}
			</div>

			<ManageRecipientsScreen
				jobId={jobId}
				settingsName={settingsName}
				recipients={allRecipients}
				afterRecipientsChange={afterRecipientsChange}
				open={manageRecipientsOpen}
				close={() => setManageRecipientsOpen(false)}
			/>
		</>
	);
};

export default JobSingleNotificationSettings;
