import React, { FC } from 'react';

import JobNotificationsSettingsInterface from './job-notifications-settings.interface';
import JobSingleNotificationSettings from '../job-single-notification-settings';
import styles from './JobNotificationsSettings.module.scss';

const JobNotificationsSettings: FC<JobNotificationsSettingsInterface> = ({jobId, hasPowerProfile}) => {
	let shortlistSettingsLabel = process.env.REACT_APP_SHORTLIST_EMAIL_SETTINGS_LABEL;
	if (!shortlistSettingsLabel) {
		shortlistSettingsLabel = "Email with the best 5 applicants sent after 15 applications have been received."
	}
	return (
		<>
		<div className={styles['job-notifications-settings']}>

		<div className={styles['notifications-title']}>Notifications</div>

		<JobSingleNotificationSettings
						jobId={jobId}
						settingsName="newRelevantApplication"
						settingsLabel={hasPowerProfile? "New relevant applications": "New applications"}
						settingsDescription={hasPowerProfile? "Email with each new relevant application.": "Email with each new application."}
		 />
		 <JobSingleNotificationSettings
						jobId={jobId}
						settingsName="shortlist"
						settingsLabel={hasPowerProfile? "Shortlist": "Shortlist"}
						settingsDescription={shortlistSettingsLabel}
		 />
		 <br />
		</div>
		</>
	);
};

export default JobNotificationsSettings;
