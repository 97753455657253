import React, { FC } from 'react';
import styles from './CandidateResumeSummary.module.scss';
import CandidateResumeSummaryInterface from './candidate-resume-summary.interface';
import classNames from 'classnames';

const CandidateResumeSummary: FC<CandidateResumeSummaryInterface> = ({
	summary, withTopBorder, withBottomBorder
}) => {
  let resumeSummary = '';
  let resumeStrengths = [];
  const s = summary || '';
  try {
    const summaryObj = JSON.parse(s);
    resumeSummary = summaryObj.summary;
    if (summaryObj.strengths) {
      resumeStrengths = summaryObj.strengths;
    }
  } catch (err) {
      // Not JSON
      resumeSummary = s;
  }

	return (
    <div className={classNames(styles['summary'], withTopBorder ? styles['with-top-border'] : '', withBottomBorder ? styles['with-bottom-border'] : '')}>
      <span className={styles['summary__section']}>
        <div className={styles['summary__text']}>
          {resumeSummary}
        </div>
        {resumeStrengths.length > 0 ? (
           <div>
              <div><br/>Candidate's strengths:</div>        
              <div className={styles['summary__strengths']}>
                <ul className={styles['summary__strengths']}>
                  {resumeStrengths.map((strength: string) => (
                  <li>{strength}</li>
                  ))}
                </ul>
              </div>
          </div>
       ): null} 
      </span>
    </div>
	);
};

export default CandidateResumeSummary;
