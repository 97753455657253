import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import ModalWindow from '../../modal-window/ModalWindow';
import Container from '../../../templates/container';
import NestedPopupHeader from '../../popup-headers/nested-popup-header/NestedPopupHeader';
import FormActions from '../../../molecules/form/form-actions';
import Button from '../../../atoms/buttons/button/Button';
import TypographyText from '../../../atoms/ui/typography-text';
import ManageRecipientsScreenInterface from './manage-recipients.interface';
import CheckboxWithLabel from '../../../molecules/form/checkbox-with-label/CheckboxWithLabel';

import { useDispatch } from 'react-redux';
import {
	changeJobNotificationsSettings,
	JobSettingsType,
} from '../../../../store/slices/user.slice';

import styles from './ManageRecipientsScreen.module.scss';
import { RecipientType } from '../../../../helpers/custom/common';
import ResizeDeviceContext from '../../../../contexts/resize-device-context';

const ManageRecipientsScreen: FC<ManageRecipientsScreenInterface> = ({
	jobId,
	settingsName,
	settingsLabel,
	recipients,
	className,
	contentClassName,
	open,
	close,
	afterRecipientsChange,
	...rest
}) => {
	const dispatch = useDispatch();
	const [allRecipients, setAllRecipients] = useState<JobSettingsType[]>([]);
	const device = useContext(ResizeDeviceContext);

	const generateRecipients = useCallback((recipients: RecipientType[]) => {
		return recipients.map((recipient) => {
			return {
				settingsName,
				settingsValue: recipient.active,
				userId: recipient.id,
				firstName: recipient.firstName,
				lastName: recipient.lastName,
			} as JobSettingsType;
		});
	}, [settingsName]);

	const onClose = useCallback(() => {
		if (recipients) setAllRecipients(generateRecipients(recipients));
		close();
	}, [close, generateRecipients, recipients]);

	const onRecipientsSave = useCallback(async () => {
		if (jobId) {
			const recipientsData = allRecipients.map((recipient) => {
				return {
					settingsName,
					settingsValue: recipient.settingsValue,
					userId: recipient.userId,
				};
			});

			await dispatch(changeJobNotificationsSettings(jobId, recipientsData));

			if (afterRecipientsChange) afterRecipientsChange();
			close();
		}
	}, [afterRecipientsChange, allRecipients, close, dispatch, jobId, settingsName]);

	const isCandidateChecked = useCallback(
		(specificRecipientId: number) => {
			return !!allRecipients.find(
				(recipient) => specificRecipientId === recipient.userId && recipient.settingsValue,
			);
		},
		[allRecipients],
	);

	const onRecipientCheckChange = useCallback((recipient: JobSettingsType, value: boolean) => {
		if (recipient) {
			setAllRecipients((prev) => {
				const newState = prev.map((r) => {
					if (r.userId === recipient.userId) r.settingsValue = value;
					return r;
				});

				return newState;
			});
		}
	}, []);

	const onSelectAllChange = useCallback((checkedStatus: boolean) => {
		setAllRecipients((prevState) => {
			const newState = prevState.map((recipient) => {
				recipient.settingsValue = checkedStatus;
				return recipient;
			});

			return newState;
		});
	}, []);

	const renderRecipients = useMemo(() => {
		return allRecipients && allRecipients.length ? (
			<div className={styles.recipients}>
				<CheckboxWithLabel
					id="all"
					label={<span className={styles.label}>Select all</span>}
					className={classNames(styles['check-item'], styles.all)}
					defaultChecked={
						allRecipients.length === allRecipients.filter((r) => r.settingsValue).length
					}
					onChange={(e) => onSelectAllChange(e.target.checked)}
				/>

				{allRecipients.map((recipient) => {
					return (
						<CheckboxWithLabel
							id={recipient.userId.toString()}
							label={
								<span className={styles.label}>
									{recipient.firstName} {recipient.lastName}
								</span>
							}
							className={styles['check-item']}
							defaultChecked={isCandidateChecked(recipient.userId)}
							onChange={(e) => onRecipientCheckChange(recipient, e.target.checked)}
						/>
					);
				})}
			</div>
		) : (
			<TypographyText>No recipients found.</TypographyText>
		);
	}, [allRecipients, isCandidateChecked, onRecipientCheckChange, onSelectAllChange]);

	useEffect(() => {
		if (recipients) setAllRecipients(generateRecipients(recipients));
	}, [generateRecipients, recipients]);

	return (
		<>
			<ModalWindow
				className={classNames(styles.wrap, className)}
				contentClassName={classNames(styles['wrap__content'], contentClassName)}
				open={open}
				close={onClose}
				{...rest}
			>
				<NestedPopupHeader
					title={'Manage recipients'}
					backgroundColor="#525668"
					className={styles.header}
					titleClassName={styles['header-title']}
					close={onClose}
				/>

				<Container maxWidth={device === 'mobile' ? "95%" : "480px"} className={styles.container}>
					<div className={styles.main}>
						<TypographyText className={styles.description}>
							<b>{settingsLabel}</b> notifications will be sent to the
							following recipients.
						</TypographyText>

						{renderRecipients}
					</div>

					<FormActions centeredContent className={styles.actions}>
						<Button
							type="button"
							minWidth={device !== 'desktop' ? 120 : 200}
							maxWidth={216}
							reversedStyles
							className={styles.btn}
							onClick={onClose}
						>
							Cancel
						</Button>

						<Button
							minWidth={device !== 'desktop' ? 120 : 200}
							maxWidth={216}
							className={styles.btn}
							onClick={onRecipientsSave}
						>
							Save
						</Button>
					</FormActions>
				</Container>
			</ModalWindow>
		</>
	);
};

export default React.memo(ManageRecipientsScreen);
