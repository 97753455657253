import React, {
	ChangeEvent,
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import {
	getCandidate,
	clearCandidate,
	CandidateInterface,
} from '../../store/slices/candidate.slice';
import {
	getCandidatesList,
	CandidatesCount,
	setHiringWithWurkCandidateId,
} from '../../store/slices/candidates.slice';
import { setActiveCompany } from '../../store/slices/companies.slice';
import { getJobOnly } from '../../store/slices/job.slice';
import { getFormattedDate, setSpecificPagination } from '../../helpers';
import {
	CandidatesStatusValues,
	QueryParametersKey,
	StorageItemKeys,
	expectedCannabisIndustrySeniorityMarks,
	expectedSeniorityMarks,
} from '../../helpers/constants';
import HiredInWurkScreen from '../../components/organisms/custom/hired-in-wurk-screen/HiredInWurkScreen';
import Notes from '../../components/organisms/custom/notes';
import MapWithDistance from '../../components/organisms/map-with-distance';
import CandidateInfoInterface from './candidate-info.interface';
import FilterContext from '../../contexts/filter-context';
import PaginationContext from '../../contexts/pagination-context';
import Tabs from '../../components/molecules/ui/tabs/Tabs';
import Title from '../../components/atoms/title/Title';
import Close from '../../components/atoms/close';
import Loader from '../../components/molecules/loader';
import LinkWithIcon from '../../components/molecules/ui/link-with-icon';
import TypographyText from '../../components/atoms/ui/typography-text';
import JobNameAndLocationInfo from '../../components/organisms/custom/job-name-and-location-info';
import CandidateSelectStatus from '../../components/organisms/custom/candidate-select-status';
import PopupWithSidebarHeader from '../../components/organisms/popup-headers/popup-with-sidebar-header';
import ContentWithSidebarLayout from '../../components/templates/popup-with-sidebar-layout';
import CandidatesInternalNavigation from '../../components/molecules/custom/candidates-internal-navigation';
import NoWorkHistoryContent from '../../components/organisms/custom/no-work-history-content';
import YearsExperienceChart from '../../components/organisms/custom/charts/years-experience-chart';

import { ReactComponent as Leaf } from '../../assets/icons/leaf.svg';
import yearsOfExperience from '../../assets/icons/years-of-experiance.svg';

import styles from './CandidateInfo.module.scss';
import useActiveCompany from '../../helpers/hooks/use-active-company/useActiveCompany';
import { getQueryForFilters } from '../../helpers/custom/url';
import MessageCandidatesScreen from '../../components/organisms/custom/message-candidates-screen';
import { clearNotesList, getNotesList } from '../../store/slices/notes.slice';
import CandidateInfoHeading from '../../components/molecules/custom/candidate-info-heading/CandidateInfoHeading';
import { APP_URLS } from '../../helpers/routes/routes';
import useCompanies from '../../helpers/hooks/use-companies/useCompanies';
import useCandidates from '../../helpers/hooks/use-candidates/useCandidates';
import useJob from '../../helpers/hooks/use-job/useJob';
import useCandidate from '../../helpers/hooks/use-candidate/useCandidate';
import useNotes from '../../helpers/hooks/use-notes/useNotes';
import CandidateExperienceList from '../../components/molecules/custom/candidate-experience-list/CandidateExperienceList';
import CommuteScore from '../../components/organisms/custom/commute-score';
import ExtendedFitScore from '../../components/molecules/ui/extended-fit-score';
import ResumeScreen from '../../components/organisms/custom/resume-screen/ResumeScreen';
import CandidateExperienceBrief from '../../components/molecules/custom/candidate-experience-brief/CandidateExperienceBrief';
import useDevice from '../../helpers/hooks/use-device';
import { GrowthBookContext } from '@growthbook/growthbook-react';
import sendFeatureFlagsContext from '../../helpers/feature-flags';

const CandidateInfo: FC<CandidateInfoInterface> = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { id, jobId } = useParams<{ jobId: string; id: string }>();

	const device = useDevice();

	const [messageCandidate, setMessageCandidate] = useState(false);
	const [activeCandidatePosition, setActiveCandidatePosition] = useState<number | undefined>();
	const [distanceBetweenPoints, setDistanceBetweenPoints] = useState<number | undefined>();
	const [dataLoading, setDataLoading] = useState(true);

	const { candidateLoading, candidateInfo } = useCandidate();
	const {
		hiringWithWurkCandidateId,
		loading: candidatesLoading,
		candidatesItems,
		candidateItemsCount,
	} = useCandidates();
	const { activeCompanyId, companies } = useCompanies();
	const { info: jobInfo, loading: jobLoading } = useJob();
	const { noteItems, notesLoading, userId: notesUserId } = useNotes();

	const {
		state: { pagination: pageNumber },
		dispatch: dispatchPaginationNumber,
	} = useContext(PaginationContext);

	const activeCompany = useActiveCompany(activeCompanyId, companies);

	const {
		state: {
			filters: { filterItem, filterOrder, text },
		},
	} = useContext(FilterContext);

	const activeTab = useMemo(
		() => new URLSearchParams(location.search).get(QueryParametersKey.tab),
		[location.search],
	);
	const [hasCustomerNotes, setHasCustomerNotes] = useState(false);

	const candidatesListSettings = useMemo(
		() => ({
			pageSize: 15,
			filter: filterItem,
			sortOrder: filterOrder,
			text: text,
		}),
		[filterItem, filterOrder, text],
	);

	const contentElementSettings = useMemo(() => ({ className: styles.content }), []);
	const sidebarElementSettings = useMemo(() => ({ className: styles.sidebar }), []);

	const jobHasPowerProfile = useMemo(() => jobInfo?.hasPowerProfile, [jobInfo]);
	const defaultFiltering = useMemo(
		() => (jobHasPowerProfile ? 'relevant' : 'all'),
		[jobHasPowerProfile],
	);

	const candidatesCount = useMemo(
		() =>
			candidatesListSettings.filter
				? candidateItemsCount[candidatesListSettings.filter as keyof CandidatesCount]
				: candidateItemsCount[defaultFiltering],
		[candidateItemsCount, candidatesListSettings, defaultFiltering],
	);

	useEffect(() => setHasCustomerNotes(!!noteItems?.length), [noteItems]);

	const featureFlagsClientContext = useContext(GrowthBookContext);

	useEffect(() => {
		if (activeCompanyId && featureFlagsClientContext?.growthbook) {
			sendFeatureFlagsContext(
				featureFlagsClientContext.growthbook,
				{companyId: `${activeCompanyId}`},
			);
		}
	}, [featureFlagsClientContext.growthbook, activeCompanyId]);

	useEffect(() => {
		if (candidateInfo?.userId && activeCompanyId && notesUserId !== candidateInfo?.userId) {
			dispatch(
				getNotesList({
					companyId: activeCompanyId,
					userId: candidateInfo?.userId,
					pageSize: 1000,
					showLogs: true,
				}),
			);
		}
	}, [activeCompanyId, candidateInfo?.userId, dispatch, notesUserId]);

	useEffect(() => {
		if (
			jobId &&
			activeCandidatePosition &&
			candidatesItems &&
			activeCandidatePosition + 1 === candidatesItems.length &&
			candidatesCount &&
			candidatesItems.length < candidatesCount
		) {
			dispatch(
				getCandidatesList(jobId, {
					pageNumber,
					...candidatesListSettings,
					filter: candidatesListSettings.filter || defaultFiltering,
					orderBy: candidatesListSettings.sortOrder,
					title: text,
				}),
			);
		}
	}, [
		activeCandidatePosition,
		candidatesCount,
		candidatesItems,
		candidatesListSettings,
		defaultFiltering,
		dispatch,
		jobId,
		pageNumber,
		text,
	]);

	useEffect(() => {
		if (candidatesCount && candidatesItems && candidatesItems.length < candidatesCount) {
			const page = candidatesItems.length / candidatesListSettings.pageSize + 1;

			setSpecificPagination(page, dispatchPaginationNumber);
		}
	}, [
		candidatesCount,
		candidatesItems,
		candidatesListSettings.pageSize,
		dispatchPaginationNumber,
	]);

	useEffect(() => {
		const companyInfo = jobInfo?.facility?.company;
		if (companyInfo) dispatch(setActiveCompany(companyInfo));
	}, [dispatch, jobInfo?.facility?.company]);

	useEffect(() => {
		const activePosition = candidatesItems?.findIndex(
			(item: CandidateInterface) => item?.id === Number(id),
		);

		if (activePosition !== -1) setActiveCandidatePosition(activePosition);
	}, [candidatesItems, id]);

	useEffect(() => {
		// if a candidate is clicked on the Candidates page (table with candidates), topbar has navigation between candidates with index and arrows
		// activeCandidatePosition - position in that list of candidates
		// url is changed while navigating between candidates
		if (
			activeCandidatePosition !== undefined &&
			candidatesItems &&
			candidatesItems[activeCandidatePosition]?.id
		) {
			const urlParams = new URLSearchParams(location.search);
			const tab = urlParams.get(QueryParametersKey.tab);

			const activeCandidateUrl = `/candidate/${jobId}/${
				candidatesItems[activeCandidatePosition].id || id
			}`;

			const activeCandidateUrlWithTab = `${activeCandidateUrl}${tab ? `?tab=${tab}` : ''}`;

			//@ts-ignore
			if (location?.pathname !== activeCandidateUrl) navigate(activeCandidateUrlWithTab);
		} else if (candidatesItems && !candidatesItems.length && id) {
			// seems like this condition is unreachable, but for some reason it was added, so left it here
			navigate(`/candidate/${jobId}/${Number(id) || id}`);
		}
	}, [
		activeCandidatePosition,
		candidatesItems,
		id,
		jobId,
		location?.pathname,
		location.search,
		navigate,
	]);

	useEffect(() => {
		if (id !== candidateInfo?.id.toString()) {
			dispatch(getCandidate(Number(id)));
		}
	}, [candidateInfo?.id, dispatch, id]);

	useEffect(() => {
		if (!candidateInfo && jobId && !jobInfo?.id) dispatch(getJobOnly(jobId));
	}, [candidateInfo, dispatch, jobId, jobInfo?.id]);

	useEffect(() => {
		// when coming back from rejection
		const { filter, sortOrder, text } = candidatesListSettings;
		const storage = localStorage.getItem('filterSettings');

		if (storage && !filter && !sortOrder && !text) {
			const filterSettings = JSON.parse(storage);

			if (filterSettings) {
				const { filter, sort, text } = filterSettings;

				candidatesListSettings.filter = filter;
				candidatesListSettings.sortOrder = sort;
				candidatesListSettings.text = text;
				localStorage.removeItem('filterSettings');
			}
		}
	}, [candidatesListSettings]);

	useEffect(() => {
		const dataProcessing = !!(
			!candidateLoading &&
			!candidatesLoading &&
			!jobLoading &&
			candidateInfo?.id &&
			jobInfo?.id &&
			!notesLoading &&
			id === candidateInfo?.id.toString() &&
			notesUserId === candidateInfo?.userId
		);

		if (dataProcessing) setDataLoading(false);
	}, [
		candidateInfo?.id,
		candidateInfo?.userId,
		candidateLoading,
		candidatesLoading,
		id,
		jobInfo?.id,
		jobLoading,
		notesLoading,
		notesUserId,
	]);

	const getKeyValueByStep = useCallback(
		(step: string, valueField: string = 'summaryText') => {
			const labels: string[] = [];
			const values: string[] = [];

			if (candidateInfo) {
				candidateInfo &&
					candidateInfo.answers &&
					candidateInfo.answers.forEach(
						({ step: itemStep, summaryText, text, summaryAnswer }) => {
							if (itemStep === step) {
								if (valueField === 'summaryText' && summaryText) {
									labels.push(summaryText);
								} else if (valueField === 'text' && text) {
									labels.push(text);
								}

								if (summaryAnswer) {
									values.push(summaryAnswer);
								}
							}
						},
					);
			}

			return {
				labels,
				values,
			};
		},
		[candidateInfo],
	);

	const getKeyValueElement = useCallback(
		(step: string, summaryTextFilter?: string[], exceptionId?: string) => {
			if (candidateInfo) {
				const result =
					candidateInfo &&
					candidateInfo.answers &&
					candidateInfo.answers
						.filter(
							({ summaryText, questionId }) =>
								exceptionId !== questionId &&
								!summaryTextFilter?.includes(summaryText),
						)
						.map(({ step: itemStep, questionId, summaryText, summaryAnswer }) => {
							if (itemStep === step) {
								return (
									<React.Fragment key={questionId}>
										<div key={questionId} className={styles.item}>
											<strong>{summaryText}</strong>: {summaryAnswer}
										</div>
									</React.Fragment>
								);
							}
							return null;
						});

				return result && result.filter((item) => item);
			}
		},
		[candidateInfo],
	);

	const foundationKeysValues = useMemo(
		() => getKeyValueByStep('foundation'),
		[getKeyValueByStep],
	);

	const totalWorkExperience = useMemo(
		() =>
			foundationKeysValues.values[
				foundationKeysValues.labels.findIndex(
					(item: string) => item === 'Total work experience',
				)
			],
		[foundationKeysValues.labels, foundationKeysValues.values],
	);

	const workExperience = useMemo(
		() => getKeyValueByStep('workexperience', 'text'),
		[getKeyValueByStep],
	);

	const nanToNumber = useCallback(
		(number: string | number) => (isNaN(Number(number)) ? 0 : Number(number)),
		[],
	);

	const textValueToNumeric = useCallback(
		(item: string) => Number(item?.replace(new RegExp(/(years|year)|\+/, 'gi'), '')),
		[],
	);

	const getSliderRange = useCallback(
		<T extends { value: any; range?: [number, number] }>(marks: T[], defaultValue?: number) => {
			const chosenObject: T | undefined = marks.find(({ value }) => value === defaultValue);

			return chosenObject?.range;
		},
		[],
	);

	const getSliderRangeLabel = useCallback(
		<T extends { value: any; rangeLabel?: string }>(marks: T[], defaultValue?: number) => {
			const chosenObject: T | undefined = marks.find(({ value }) => value === defaultValue);

			return chosenObject?.rangeLabel;
		},
		[],
	);

	const cannabisWorkExperienceLabels = useMemo(
		() => [
			<strong className={styles['cannabis-work-experience-label']}>
				<span className={styles['cannabis-work-experience-label__content']}>
					<Leaf className={styles['label-icon']} />{' '}
					{workExperience?.values[workExperience?.values.length - 1]}
				</span>
			</strong>,
		],
		[workExperience?.values],
	);

	const cannabisWorkExperienceNumericValues = useMemo(
		() => [
			nanToNumber(
				textValueToNumeric(workExperience?.values[workExperience?.values.length - 1]),
			),
		],
		[nanToNumber, textValueToNumeric, workExperience?.values],
	);

	const workExperienceLabels = useMemo(() => {
		const labels = [...workExperience.labels];
		labels.pop();

		return labels;
	}, [workExperience.labels]);

	const workExperienceNumericValues = useMemo(() => {
		const values = workExperience.values.length
			? [...workExperience.values].map((item) => {
					const number = textValueToNumeric(item);

					return nanToNumber(number);
			  })
			: [];

		values.pop();

		return values;
	}, [nanToNumber, textValueToNumeric, workExperience.values]);

	const workExperienceValueLabels: number[] = useMemo(
		() => (workExperienceNumericValues?.length ? Array.from(Array(6).keys()) : []),
		[workExperienceNumericValues?.length],
	);

	// @ts-ignore
	const overallWorkExperienceValueLabels: number[] = useMemo(() => {
		const values = Array.from(Array(21).keys())
			.map((item) => item % 5 === 0 && item)
			.filter((item) => item);

		values.unshift(0);

		return values;
	}, []);

	const overallWorkExperienceNumericValues = useMemo(() => {
		const values = [totalWorkExperience].map((item) => {
			const number = textValueToNumeric(item);

			return nanToNumber(number);
		});

		return values;
	}, [nanToNumber, textValueToNumeric, totalWorkExperience]);

	const overallWorkExperienceLabels = useMemo(
		() => [
			<strong className={styles['cannabis-work-experience-label']}>
				<span className={styles['cannabis-work-experience-label__content']}>
					<img
						src={yearsOfExperience}
						alt="Years of experience"
						className={classNames(styles['label-icon'], styles.overall)}
					/>
					{totalWorkExperience}
				</span>
			</strong>,
		],
		[totalWorkExperience],
	);

	const background = useMemo(
		() => getKeyValueElement('foundation', ['Total work experience'], 'q206'),
		[getKeyValueElement],
	);

	const relocateInfoObject = useMemo(
		() => candidateInfo?.answers?.find((item) => item.questionId === 'q206'),
		[candidateInfo?.answers],
	);

	const linkedInUrl = useMemo(
		() =>
			candidateInfo?.linkedInUrl &&
			`https://${candidateInfo.linkedInUrl.replace('https://', '')}`,
		[candidateInfo?.linkedInUrl],
	);

	const afterStatusChange = useCallback(() => {
		dispatch(setHiringWithWurkCandidateId(undefined));
	}, [dispatch]);

	const workHistory = useMemo(
		() =>
			candidateInfo?.workHistory?.length ? (
				<CandidateExperienceList candidateWorkHistory={candidateInfo?.workHistory} />
			) : (
				<NoWorkHistoryContent
					{...{ linkedInUrl }}
					resumeUrl={candidateInfo?.resumeUrl}
					onResumeClick={() => {
						navigate({
							search: `?tab=resume`,
						});
					}}
					minHeight={80}
				/>
			),
		[candidateInfo?.resumeUrl, candidateInfo?.workHistory, linkedInUrl, navigate],
	);

	const highestDegreeOfEducation = useMemo(
		() =>
			foundationKeysValues.values[
				foundationKeysValues.labels.findIndex(
					(item: string) => item === 'Highest degree of education',
				)
			],
		[foundationKeysValues.labels, foundationKeysValues.values],
	);

	const appliedDate = useMemo(
		() => candidateInfo?.createdAt && new Date(candidateInfo.createdAt).getTime(),
		[candidateInfo?.createdAt],
	);

	const getFiltersSettings = useCallback(() => {
		const { filter, sortOrder, text } = candidatesListSettings;

		const params = {
			...(filter && { filter }),
			...(sortOrder && { sort: sortOrder }),
			...(text && { text }),
		};

		return JSON.stringify(params);
	}, [candidatesListSettings]);

	const onStatusChanged = useCallback(
		(event: ChangeEvent) => {
			//@ts-ignore
			if (event.currentTarget.value === CandidatesStatusValues.rejected.toString()) {
				localStorage.setItem(StorageItemKeys.filterSettings, getFiltersSettings());

				navigate(APP_URLS.candidateRejection, {
					state: {
						candidates: [
							{
								id: candidateInfo?.id,
								firstName: candidateInfo?.firstName,
								lastName: candidateInfo?.lastName,
							},
						],
						source: 'details',
						jobId,
					},
				});
			}
		},
		[
			candidateInfo?.firstName,
			candidateInfo?.id,
			candidateInfo?.lastName,
			getFiltersSettings,
			jobId,
			navigate,
		],
	);

	const pageTopSection = useMemo(
		() => (
			<div
				className={classNames(
					styles.top,
					!activeTab || activeTab === 'details' ? styles['with-border'] : '',
				)}
			>
				<div className={styles['top__header']}>
					<CandidateInfoHeading
						hasCustomerNotes={hasCustomerNotes}
						onMessageClick={() => setMessageCandidate(true)}
						onNotesClick={() => {
							if (activeTab !== 'notes') {
								navigate({
									search: '?tab=notes',
								});
							}
						}}
					/>

					<span className={styles['applied-date']}>
						Applied: {appliedDate && getFormattedDate(appliedDate)}
					</span>
				</div>

				<div className={styles['top__footer']}>
					<CandidateExperienceBrief
						{...{
							highestDegreeOfEducation,
							totalWorkExperience,
						}}
					/>

					<div className={styles.status}>
						<span className={styles['status-label']}>Status:</span>

						{candidateInfo?.id ? (
							<CandidateSelectStatus
								statusLabel={candidateInfo?.statusLabel}
								hiredInWurk={candidateInfo?.hiredInWurk}
								candidateId={candidateInfo?.id}
								minWidth={150}
								defaultValue={candidateInfo?.status}
								onChange={onStatusChanged}
							/>
						) : null}
					</div>
				</div>
			</div>
		),
		[
			activeTab,
			appliedDate,
			candidateInfo?.hiredInWurk,
			candidateInfo?.id,
			candidateInfo?.status,
			candidateInfo?.statusLabel,
			hasCustomerNotes,
			highestDegreeOfEducation,
			navigate,
			onStatusChanged,
			totalWorkExperience,
		],
	);

	const content = useMemo(
		() =>
			candidateInfo?.id && !notesLoading ? (
				<>

					{candidateInfo?.personality && (
						<div className={styles.personality}>
							<span className={styles['personality__text']}>
								{candidateInfo?.personality}
							</span>
						</div>
					)}

					<section className={styles['answer-section']}>
						<Title
							component="h3"
							fontSize={22}
							marginBottom={16}
							fontWeight={600}
							className={styles['answer-section__title']}
						>
							Experience
						</Title>

						{workHistory}
					</section>

					<section className={styles['answer-section']}>
						<YearsExperienceChart
							title="Overall"
							maxValue={20}
							labels={overallWorkExperienceLabels}
							valueLabels={overallWorkExperienceValueLabels}
							customValueLabels={overallWorkExperienceValueLabels.map((item, index) =>
								index === overallWorkExperienceValueLabels.length - 1
									? `${item}+`
									: item,
							)}
							values={overallWorkExperienceNumericValues}
							rangeLabel={getSliderRangeLabel<any>(
								expectedSeniorityMarks,
								jobInfo?.workSeniority,
							)}
							crossing={getSliderRange<any>(
								expectedSeniorityMarks,
								jobInfo?.workSeniority,
							)}
						/>
					</section>

					{activeCompany?.allowsCannabis && (
						<section className={styles['answer-section']}>
							<YearsExperienceChart
								maxValue={5}
								title="Cannabis industry"
								labels={cannabisWorkExperienceLabels}
								valueLabels={workExperienceValueLabels}
								values={cannabisWorkExperienceNumericValues}
								customValueLabels={workExperienceValueLabels.map((item, index) =>
									index === workExperienceValueLabels.length - 1
										? `${item}+`
										: item,
								)}
								rangeLabel={getSliderRangeLabel<any>(
									expectedCannabisIndustrySeniorityMarks,
									jobInfo?.cannabisSeniority,
								)}
								crossing={getSliderRange<any>(
									expectedCannabisIndustrySeniorityMarks,
									jobInfo?.cannabisSeniority,
								)}
							/>
						</section>
					)}

					{workExperience && (
						<section className={styles['answer-section']}>
							<YearsExperienceChart
								title="Other industries"
								maxValue={5}
								labels={workExperienceLabels}
								valueLabels={workExperienceValueLabels}
								values={workExperienceNumericValues}
								customValueLabels={workExperienceValueLabels.map((item, index) =>
									index === workExperienceValueLabels.length - 1
										? `${item}+`
										: item,
								)}
							/>
						</section>
					)}

					{background && (
						<section className={styles['answer-section']}>
							<Title
								component="h3"
								fontSize={18}
								marginBottom={16}
								fontWeight={600}
								className={styles['answer-section__title']}
							>
								Background
							</Title>

							{background}
						</section>
					)}

					{candidateInfo?.cover && (
						<section className={styles['answer-section']}>
							<Title
								component="h3"
								fontSize={18}
								marginBottom={16}
								fontWeight={600}
								className={styles['answer-section__title']}
							>
								Cover letter
							</Title>

							<TypographyText className={styles['answer-section__text']}>
								{candidateInfo?.cover}
							</TypographyText>
						</section>
					)}
				</>
			) : (
				<Loader className={styles.loader} />
			),
		[
			activeCompany?.allowsCannabis,
			background,
			candidateInfo?.cover,
			candidateInfo?.id,
			candidateInfo?.personality,
			cannabisWorkExperienceLabels,
			cannabisWorkExperienceNumericValues,
			getSliderRange,
			getSliderRangeLabel,
			jobInfo?.cannabisSeniority,
			jobInfo?.workSeniority,
			notesLoading,
			overallWorkExperienceLabels,
			overallWorkExperienceNumericValues,
			overallWorkExperienceValueLabels,
			workExperience,
			workExperienceLabels,
			workExperienceNumericValues,
			workExperienceValueLabels,
			workHistory
		],
	);

	const renderCommuteScoreSection = useMemo(() => {
		return candidateInfo ? (
			<div className={styles.commute}>
				<TypographyText className={styles.title}>Commute</TypographyText>
				<CommuteScore
					className={styles.rating}
					scoreClass={candidateInfo.commuteScoreClass}
					largeSize
				/>
			</div>
		) : null;
	}, [candidateInfo]);

	const renderFitScoreSection = useMemo(() => {
		return candidateInfo ? (
			<>
				<h3 className={styles['sidebar__title']}>FitScore™</h3>

				<div className={styles['score-wrap']}>
					{candidateInfo.score ? (
						<ExtendedFitScore
							className={styles['extended-score-content']}
							score={candidateInfo?.score}
							hardScore={candidateInfo?.hardScore}
							softScore={candidateInfo?.softScore}
							commuteScoreClassName={candidateInfo?.commuteScoreClass}
						/>
					) : null}
				</div>
			</>
		) : null;
	}, [candidateInfo]);

	const sidebar = useMemo(
		() => (
			<>
				{candidateInfo ? (
					<>
						{candidateInfo.commuteScoreClass && !jobHasPowerProfile
							? renderCommuteScoreSection
							: renderFitScoreSection}

						{jobInfo?.facility ? (
							<MapWithDistance
								className={styles['map-with-distance']}
								maxWidth={device !== 'desktop' ? 300 : undefined}
								candidateLongitude={candidateInfo?.longitude}
								candidateLatitude={candidateInfo?.latitude}
								jobLongitude={jobInfo?.facility?.longitude}
								jobLatitude={jobInfo?.facility?.latitude}
								candidateState={candidateInfo?.state}
								jobState={jobInfo?.facility?.state}
								{...{ setDistanceBetweenPoints }}
							/>
						) : null}

						<div className={styles['info-items']}>
							{candidateInfo.zip && (
								<div
									className={classNames(
										styles['info-item'],
										styles['candidate-location-info'],
									)}
								>
									<div className={styles.item}>
										<div className={styles['candidate-location-info__item']}>
											<strong>Candidate zipcode</strong>: {candidateInfo.zip}{' '}
											({candidateInfo.state})
										</div>
										{distanceBetweenPoints && (
											<div
												className={styles['candidate-location-info__item']}
											>
												<strong>Approximate commute:</strong>{' '}
												{distanceBetweenPoints} miles
											</div>
										)}
										{relocateInfoObject && (
											<div
												className={styles['candidate-location-info__item']}
											>
												<strong>Acceptable commute:</strong>{' '}
												{relocateInfoObject?.summaryAnswer}
											</div>
										)}
									</div>
								</div>
							)}

							<div className={styles['info-item']}>
								<LinkWithIcon
									icon="download"
									iconClassName={styles['download-icon']}
									target="_blank"
									href={candidateInfo.resumeUrl}
									download
									disabled={!candidateInfo.resumeUrl}
									className={classNames(
										styles.download,
										!candidateInfo.resumeUrl ? styles.disabled : '',
									)}
								>
									Download resume
								</LinkWithIcon>
							</div>

							<div className={styles['info-item']}>
								<LinkWithIcon
									className={classNames(
										styles.linkedin,
										!linkedInUrl ? styles.disabled : '',
									)}
									icon="linkedin"
									href={linkedInUrl}
									target="_blank"
									disabled={!linkedInUrl}
								>
									View LinkedIn profile
								</LinkWithIcon>
							</div>

							<div className={styles['info-item']}>
								<LinkWithIcon
									icon="email"
									href={`mailto:${candidateInfo.email}`}
									disabled={!candidateInfo.email}
								>
									{candidateInfo.email ? candidateInfo.email : 'No email'}
								</LinkWithIcon>
							</div>

							<div className={styles['info-item']}>
								<LinkWithIcon
									icon="phone"
									href={`tel:${candidateInfo.phoneNumber}`}
									disabled={!candidateInfo.phoneNumber}
								>
									{candidateInfo.phoneNumber
										? candidateInfo.phoneNumber
										: 'No phone number'}
								</LinkWithIcon>
							</div>

						</div>
					</>
				) : null}
			</>
		),
		[
			candidateInfo,
			distanceBetweenPoints,
			jobHasPowerProfile,
			jobInfo?.facility,
			linkedInUrl,
			relocateInfoObject,
			renderCommuteScoreSection,
			renderFitScoreSection,
			device,
		],
	);

	const onCloseClick = useCallback(() => {
		if (!candidatesItems?.length) navigate(`/jobs/${jobId}`);
		else {
			const { filter, sortOrder, text } = candidatesListSettings;
			const queryStr = getQueryForFilters({ filter, sort: sortOrder, text });

			navigate(`/jobs/${jobId}` + `${queryStr ? '?' + queryStr : ''}`);
		}

		dispatch(clearCandidate());
		dispatch(clearNotesList());
	}, [candidatesItems?.length, candidatesListSettings, dispatch, jobId, navigate]);

	// to update candidate's status after message sent
	const afterMessageSent = useCallback(() => {
		dispatch(getCandidate(Number(id)));
	}, [dispatch, id]);

	const renderInternalNavigation = useMemo(() => {
		return (
			<CandidatesInternalNavigation
				{...{ candidatesCount }}
				className={styles['candidate-nav']}
				candidateSerialNumber={
					activeCandidatePosition !== undefined ? activeCandidatePosition + 1 : null
				}
				backHandler={() => {
					dispatch(clearCandidate());

					if (activeCandidatePosition && activeCandidatePosition !== 0) {
						setActiveCandidatePosition(activeCandidatePosition - 1);
					} else {
						setActiveCandidatePosition(activeCandidatePosition);
					}
				}}
				nextHandler={() => {
					dispatch(clearCandidate());

					if (
						activeCandidatePosition !== undefined &&
						activeCandidatePosition + 1 !== candidatesItems?.length
					) {
						setActiveCandidatePosition(activeCandidatePosition + 1);
					}
				}}
			/>
		);
	}, [activeCandidatePosition, candidatesCount, candidatesItems?.length, dispatch]);

	const pageTabs = useMemo(
		() => (
			<Tabs
				className={styles.tabs}
				tabClassName={styles.tab}
				tabsWrapClassName={styles['tabs-wrap']}
				defaultActiveTab={activeTab || 'details'}
				tabs={[
					{
						key: 'details',
						text: 'Details',
						component: <>{content}</>,
					},
					{
						key: 'resume',
						text: 'Resume',
						component: (
							<ResumeScreen candidateWorkHistory={candidateInfo?.workHistory} />
						),
					},
					{
						key: 'notes',
						text: 'Notes',
						component: <>{candidateInfo?.id ? <Notes /> : null}</>,
					},
				]}
			/>
		),
		[activeTab, candidateInfo?.id, candidateInfo?.workHistory, content],
	);

	const renderMessageModal = useMemo(() => {
		return candidateInfo?.id ? (
			<MessageCandidatesScreen
				candidatesDataToMessage={[
					{
						id: candidateInfo?.id as string,
						firstName: candidateInfo?.firstName,
						lastName: candidateInfo?.lastName,
					},
				]}
				open={messageCandidate}
				close={() => setMessageCandidate(false)}
				afterMessageSentHandler={afterMessageSent}
			/>
		) : null;
	}, [
		afterMessageSent,
		candidateInfo?.firstName,
		candidateInfo?.id,
		candidateInfo?.lastName,
		messageCandidate,
	]);

	const renderWurkScreen = useMemo(() => {
		return (
			<HiredInWurkScreen
				{...{ afterStatusChange }}
				open={!!hiringWithWurkCandidateId}
				close={() => dispatch(setHiringWithWurkCandidateId(undefined))}
			/>
		);
	}, [afterStatusChange, dispatch, hiringWithWurkCandidateId]);

	const renderPopupHeader = useMemo(() => {
		return (
			<PopupWithSidebarHeader className={styles.header}>
				<div className={styles['header-wrapper']}>
					<JobNameAndLocationInfo className={styles['job-info']} />

					{candidatesItems?.length ? renderInternalNavigation : null}

					<Close className={styles.close} onClick={onCloseClick} />
				</div>
			</PopupWithSidebarHeader>
		);
	}, [candidatesItems?.length, onCloseClick, renderInternalNavigation]);

	const pageMainBar = useMemo(() => {
		return (
			<>
				{pageTopSection}
				{pageTabs}
			</>
		);
	}, [pageTabs, pageTopSection]);

	const renderMainContent = useMemo(() => {
		return (
			<ContentWithSidebarLayout
				className={styles.layout}
				content={pageMainBar}
				{...{
					contentElementSettings,
					sidebar,
					sidebarElementSettings,
				}}
			/>
		);
	}, [contentElementSettings, sidebar, sidebarElementSettings, pageMainBar]);

	if (dataLoading) return <Loader wide />;

	return (
		<>
			{renderPopupHeader}

			{renderMainContent}

			{renderWurkScreen}

			{renderMessageModal}
		</>
	);
};

export default React.memo(CandidateInfo);
